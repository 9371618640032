// 订单评价
<template>
  <div class="evaluateOrder">
    <!-- {{orderdetail.id}} -->
    <el-row class="mainInfo">
      <el-col class="evaluateInfo">
        <el-col class="evaluateInfotitle">请您为这次订单做出评价</el-col>
        <ul class="DesMsgBox">
          <li  v-for="(item, index) in descData" :key="index"  @click="descFun(item)">
            <span :class="item.flag?'active':''" >{{item.evaluationName}}</span>
          </li>
        </ul>
        <el-col class="desc">
          <el-input
            type="textarea"
            v-model="desc"
            maxlength="50"
            show-word-limit
            placeholder="您对本次交易还满意吗？快来分享您的购买体验吧（50字以内）"
          ></el-input>
        </el-col>
        <el-col>
          <el-upload
            action="#"
            list-type="picture-card"
            multiple
            :limit="5"
            :show-file-list="boolean"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
            :auto-upload="false"
            :on-change="imgSaveToUrl"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :file-list="fileImgList"
            ref="upload"
          >
            <img src="../../assets/uploadImg.png" alt="" />
            <div class="el-upload__tip" slot="tip">
              当前<span class="redMsg">{{PicNums}}</span>张，最多可上传<span
                class="redMsg"
                >5</span
              >张图片
            </div>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-col>
      </el-col>
     
    </el-row>
    <el-row class="mainInfo">
      <el-col class="evaluateMain">
         <el-col class="evaluateInfotitle">请您为这次服务打分</el-col>
         <div class="rateBox">
            <el-col :span="8">
               <el-col class="rateMsg" :span="4">订单服务</el-col>
               <el-col :span="20">
                  <el-rate
                    v-model="OrderVal" :texts="texts"   :colors="colors"
                    show-text>
                  </el-rate>
               </el-col>
            </el-col>
            <el-col :span="8">
              <el-col class="rateMsg" :span="4">物流服务</el-col>
               <el-col :span="20">
                  <el-rate
                    v-model="logVal" :texts="texts"  :colors="colors"
                    show-text>
                  </el-rate>
               </el-col>
            </el-col>
            <el-col :span="8">
              <el-col class="rateMsg" :span="4">客服服务</el-col>
               <el-col :span="20">
                  <el-rate
                    v-model="customerVal" :texts="texts2"  :colors="colors"
                    show-text>
                  </el-rate>
               </el-col>
            </el-col>
          </div>
         <el-col class="evaluateInfotitle">您的身份</el-col>
          <ul class="MemberBox">
            <li  :class="memberId==1?'active':''" @click="MemberFun(1)">采购员</li>
            <li  :class="memberId==2?'active':''" @click="MemberFun(2)">店长</li>
            <li  :class="memberId==3?'active':''" @click="MemberFun(3)">老板</li>
          </ul>
      </el-col>
    </el-row>

     <el-row class="mainInfo">
       <span class="submitBtn" @click="SaveEvaluate()">提交</span>
       <span class="submitMsg">填写评价，完成后可的5积分哦～</span>
     </el-row>
  </div>
</template>

<script>
import Qs from "qs";
import { EvaluateList,putFile , SaveEvaluate} from "api/order.js";
export default {
  name: "evaluateOrder",
  data() {
    return {
      OrderVal: null,  //订单服务
      logVal: null,//物流服务
      customerVal: null,//客服服务
      colors: ['#ff3333', '#ff3333', '#ff3333'],
      texts: ['非常不满意', '不满意', '一般', '满意', '五星好评'],
      texts2: ['0~2分', '3~4分', '5~6分', '7~8分', '9~10分'],
      memberStoreId:'',
      //图片上传
      // 当前上传图片数量
      PicNums:0,
      //是否展示图片列表
      boolean: true,
      dialogImageUrl: "",
      dialogVisible: false,
      posterURL: "",
      // 上传图片路径集合
      productPics:[],
      productPicsStr:'',
      //上传图片合集
      fileImgList: [],
      descArr: [], //问题描述
      desc:'', //问题描述
      descData:[],
      memberId:0 ,//身份id
      labelsIds: "",   //评价标签集合
    };
  },
  methods: {
    // 点击评价标签
    descFun(CurrentItem){
      CurrentItem.flag=!CurrentItem.flag
      if(CurrentItem.flag){
        this.descArr.push(CurrentItem.id)
      }else{
        // alert(this.descArr.findIndex(item => item === CurrentItem.id))
        this.descArr.splice(this.descArr.findIndex(item => item === CurrentItem.id), 1)
      }
      // console.log(this.descArr)
    },
    // 点击身份事件
    MemberFun(memberId){
      this.memberId=memberId
    },
    // 提交评价
    SaveEvaluate(){
        if(this.PicNums>0){
              let productPics = "";
              this.productPics.forEach((item) => {
                productPics += item.link + ",";
              });
              this.productPicsStr=productPics.substring(0,productPics.length-1)
        }

      // 获取评价标签 start
      if(this.descArr.length<1){
           this.$message({
              message:'请选择评价标签',
              type: 'warning'
            });
            return
      }else{
        this.labelsIds=''
        this.descArr.forEach(item=>{
          this.labelsIds+=item+','
        })
        this.labelsIds=this.labelsIds.substring(0,this.labelsIds.length-1)
      }
          // 获取评价标签 end

      if(this.desc==''){
        this.$message({
          message:'请先填写评价',
          type: 'warning'
        });
        return
      }
      if(this.OrderVal<1){
        this.$message({
          message:'请对订单服务评分',
          type: 'warning'
        });
        return
      }
      if(this.logVal<1){
        this.$message({
          message:'请对物流服务评分',
          type: 'warning'
        });
        return
      }
      if(this.customerVal<1){
        this.$message({
          message:'请对客服服务评分',
          type: 'warning'
        });
        return
      }
      if(this.memberId<1){
        this.$message({
          message:'请选择您的身份',
          type: 'warning'
        });
        return
      }
      let datas={
          "labels": this.labelsIds,   //评价标签集合
          "content": this.desc,    //评价内容
          "customerService":  this.customerVal,   //客服服务评分
          "identity": this.memberId,   //	身份（1.采购员 2.店长 3.老板）
          "logisticsService": this.logVal,   //物流服务评分
          "memberStoreId":this.memberStoreId,  //会员门店Id
          "orderId": this.$route.query.orderId,  //评价订单id
          "orderService": this.OrderVal,  //订单服务评分
          "pics": this.productPicsStr, //图片集合
          "status": 0   //	业务状态
        }

      SaveEvaluate(datas).then(
        data=>{
          if(data.data.code==200){
             this.$message({
                message: '感谢您的用心评价，获得5积分',
                type: 'success'
              });
                this.$router.push({ name: "MyOrder" });
              // this.reload();
          }else{
            this.$message({
              message: data.data.msg,
              type: "warning",
            });
          }
        }
      )
    },
    // 选取图片后自动回调，里面可以获取到文件
    imgSaveToUrl(file) {
      let SAMEJPG = false;
      this.fileImgList.forEach((item) => {
        if (
          item.size == file.size &&
          item.lastModified == file.lastModified &&
          item.name == file.name
        ) {
          SAMEJPG = true;
          return false;
        }
      });
      this.fileImgList.push(file);

      let fileName = file.name;
      let regex = /(.jpg|.jpeg|.gif|.png|.bmp)$/;
      if (regex.test(fileName.toLowerCase())) {
        this.posterURL = file.url;
      } else {
        this.fileImgList = this.fileImgList.splice(
          0,
          this.fileImgList.length - 1
        );
        this.$message.error("请选择图片文件");
      }
      if (SAMEJPG) {
        this.fileImgList = this.fileImgList.splice(
          0,
          this.fileImgList.length - 1
        );
        this.$message.error("不能上传相同图片文件");
      } else {
        let formfile = new FormData();
        formfile.append("file", file.raw);

        console.log(file.raw)
        putFile(formfile).then((data) => {
          this.productPics.push(data.data.data);
           this.PicNums=this.productPics.length
        });
      }
    },
    //移除
    handleRemove(file) {
      // , fileList
      console.log('上传图片合集',this.fileImgList)
      this.fileImgList.forEach((item, i) => {
        if (item.name == file.name) {
          this.fileImgList.splice(i, 1);
        }
      });
      this.productPics.forEach((item, i) => {
        if (item.originalName == file.name) {
          this.productPics.splice(i, 1);
           this.PicNums=this.productPics.length
        }
      });
    },
    //预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //定义超出限制时
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 5个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
  created() {
     this.memberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
    EvaluateList().then(res=>{
       res.data.data.forEach((item) => {
        item.flag =false;
      });
      let result =res.data.data
      this.descData=result
    })
  },
  components: {},
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
/deep/ .el-rate__icon{
  font-size:34px;
}
.redMsg {
  color: red;
}
.evaluateOrder {
  width: 1200px;
  margin: 20px 0 auto;
    .mainInfo {
      background: #fff;
      padding: 20px;
      margin-bottom:20px;
      .evaluateInfo {
        font-size: 14px;
        padding-left: 13px;
        padding-bottom: 0px;
        .fonts();
        color: #333;
        .evaluateInfotitle {
          font-weight: bold;
          font-size: 16px;
          padding-bottom: 12px;
        }
        .DesMsgBox {
          li {
            float: left;
            margin-right: 5px;
            background: #f0f0f0;
            color: #93939b;
            span{
              padding: 3px 10px;
              background: #f0f0f0;
              color: #93939b;
               &:hover {
                  background: #e00b0b;
                  color: #fff;
                }
                &.active {
                  background: #e00b0b;
                  color: #fff;
                }
            }
           
          }
        }
        /deep/.el-textarea {
          margin: 12px 0;
          display: inline-block;
          width: 50%;
          vertical-align: bottom;
          textarea {
            height: 90px;
            line-height: 30px;
          }
        }
      }
    
      /deep/.el-upload-list--picture-card .el-upload-list__item {
        width: 80px;
        height: 80px;
        float: left;
      }
      .el-upload.el-upload--picture-card img {
        width: 80px;
        height: 80px;
      }
      /deep/.el-upload--picture-card {
        background-color: #fff;
        border: 1px dashed #ccc;
        border-radius: 0px;
        box-sizing: border-box;
        width: 80px;
        height: 80px;
        line-height: 80px;
        vertical-align: top;
        color: #d8d8d8;
        float: left;
      }
      .el-upload__tip {
        font-size: 12px;
        color: #606266;
        line-height: 80px;
        margin-left: 20px;
        float: left;
      }
      .evaluateMain{
        font-size: 14px;
        padding-left: 13px;
        padding-bottom: 0px;
        .fonts();
          color: #333;
          .evaluateInfotitle {
            font-weight: bold;
            font-size: 16px;
            padding-bottom: 12px;
          }
          .rateBox{
            padding:10px 0 26px;
            height: 36px;
            clear: both;
            .rateMsg{
              line-height: 35px;
                font-size: 14px;
                font-weight: bold;
                color: #636366;
            }
          }

          .MemberBox {
            li {
              padding: 3px 10px;
              float: left;
              margin-right: 5px;
              background: #f0f0f0;
              color: #93939b;
              &:hover {
                background: #e00b0b;
                color: #fff;
              }
              &.active{
                background: #e00b0b;
                color: #fff;
              }
            }
          }
      }

      .submitBtn{
        display: inline-block;
        width: 160px;
        height: 48px;
        color: #fff;
        background: #E00B0B;
        text-align: center;
        line-height:48px;
        font-size: 16px;
        margin-left: calc(50% - 80px);
        margin-right: 20px;
      }
      .submitMsg{
        font-size: 12px;
        color:#666666
      }
    }
  
}

</style>
